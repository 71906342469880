import React from "react";

export default function CollectionsModalTable(props) {
  const nameSort = (a, b) => {
    // Regular expression to match the pattern "Prefix Number"
    const regex = /(\D*)(\d*)/;
    const matchA = a.name.match(regex);
    const matchB = b.name.match(regex);

    // Extract prefix and number from both strings
    const prefixA = matchA[1], numberA = parseInt(matchA[2], 10);
    const prefixB = matchB[1], numberB = parseInt(matchB[2], 10);

    // Compare prefixes if they are different
    if (prefixA !== prefixB) {
        return prefixA.localeCompare(prefixB);
    }

    // If one string has a number and the other does not, the one with the number goes last
    if (isNaN(numberA) && !isNaN(numberB)) return -1;
    if (!isNaN(numberA) && isNaN(numberB)) return 1;

    // If both have numbers, compare numerically
    if (!isNaN(numberA) && !isNaN(numberB)) {
        return numberA - numberB;
    }

    // If both are purely non-numerical or identical, compare alphabetically
    return a.name.localeCompare(b.name);
  };

  return (
    <table className="table table-striped collections-modal-table">
      <thead>
        <tr>
          <th>Collection</th>
          <th>Count</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.collections.slice(0).sort(nameSort).map((collection) => (
          <tr key={collection.slug}>
            <td style={{ fontSize: '1.5em', fontWeight: 'bold' }}>{collection.name}</td>
            <td style={{ fontSize: '1.5em' }}>{collection[props.attr]}</td>
            <td style={{ width: '25%' }}>
              <button className={'btn btn-block joystix ' + (props.btnClassName || 'btn-default')} onClick={() => props.onBtnClick(collection)}>
                {props.btnText}
                {!!props.btnIcon && <span style={{ marginLeft: 8 }}>{props.btnIcon}</span>}
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

import React from "react";

export default (props) => {
  return (
    <div className={'panel panel-' + (props.context || 'default') + ' ' + (props.className || "")} id={props.id} style={props.style}>
      {props.heading && <div className="panel-heading">{props.heading}</div>}
      <div className="panel-body">
        {props.children}
      </div>
    </div>
  );
};

import React from "react";

import 'url-search-params-polyfill';

import AddSentenceToCollectionBtn from "../AddSentenceToCollectionBtn";
import ChunkProgressBar from "../Dashboard/ChunkProgressBar";
import ClozeSentence from "../ClozeSentence";
import CollectionClozeSentenceEditorModal from "../CollectionClozeSentenceEditorModal";
import DailyGoalStats from "../DailyGoalStats";
import DiscussionBtn from "../DiscussionBtn";
import FlagSprite from "../FlagSprite";
import Icon from "../Icon";
import Panel from "../Panel";
import ReportErrorBtn from "./ReportErrorBtn";
import TeachingCollectionResultsForm from "./TeachingCollectionResultsForm";
import Tokens from "./Tokens";

import { pctStr, ordinalSuffix } from "../../helpers";

class RoundResultsCollectionClozeSentenceTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sentenceEditorModalVisible: false
    };
  }

  componentDidMount() {
    $("body").popover({
      container: "body",
      selector: ".collection-progress [data-toggle=popover]"
    });
  }

  renderFavoriteBtn() {
    const { collectionClozeSentence, isPro, updateSentence, updating } = this.props;

    if(!isPro) {
      return (
        <button disabled={updating} className="btn btn-default favorite" title="Favorite sentence" data-toggle="modal" data-target="#favorite-pro-promo-modal" title="Toggle favorite">
          <Icon name="star-empty" />
        </button>
      );
    }

    return (
      <button
        className={'btn btn-default favorite' + (collectionClozeSentence.favorited ? ' active' : '')}
        disabled={updating}
        onClick={() => updateSentence('favorited', !collectionClozeSentence.favorited)}
        title="Toggle favorite"
      >
        <span className={'glyphicon glyphicon-star' + (collectionClozeSentence.favorited ? '' : '-empty')}></span>
      </button>
    );
  }

  renderAudioBtn() {
    const { collectionClozeSentence, isTtsAvailable, playTts, updating } = this.props;

    if(!isTtsAvailable(collectionClozeSentence)) {
      return null;
    }

    return (
      <button disabled={updating} className="btn btn-default audio" onClick={() => playTts({ sentence: collectionClozeSentence })} title="Play sentence audio">
        <Icon name="volume-up" />
      </button>
    );
  }

  renderControls() {
    const {
      baseLanguageEnglishName,
      collectionClozeSentence,
      collectionsUrl,
      isPro,
      isReportable,
      isSignedIn,
      nextReviewByLevel,
      reportErrorUrl,
      targetLanguageEnglishName,
      targetLanguageName,
      updateSentence,
      updating
    } = this.props;

    if(!isSignedIn) {
      return null;
    }

    return (
      <div className="sentence-controls text-right">
        <div className="btn-group btn-group-sm" role="group" aria-label="Sentence controls">
          {this.renderAudioBtn()}
          <button disabled={updating} className="btn btn-default edit-sentence" onClick={() => this.setState({ sentenceEditorModalVisible: true })} title="Edit sentence">
            <Icon name="pencil" />
          </button>
          {this.renderFavoriteBtn()}
          <button
            className={'btn btn-default ignore' + (collectionClozeSentence.ignored ? ' active' : '')}
            disabled={updating}
            onClick={() => updateSentence('ignored', !collectionClozeSentence.ignored)}
            title="Toggle ignore"
          >
            <Icon name="ban-circle" />
          </button>
        </div>
        <br />
        <div className="btn-group btn-group-sm" role="group" aria-label="More sentence controls" style={{ marginTop: 10 }}>
          {this.renderTokensBtn()}
          <AddSentenceToCollectionBtn
            className="btn btn-default add-sentence-to-collection"
            collectionClozeSentence={collectionClozeSentence}
            collectionsUrl={collectionsUrl}
            copyable={true}
            isPro={isPro}
            nextReviewByLevel={nextReviewByLevel}
            text={false}
            title="Add sentence to collection"
          />
          <DiscussionBtn
            baseLanguageEnglishName={baseLanguageEnglishName}
            className="btn btn-default discussion"
            clozeSentence={collectionClozeSentence}
            disabled={updating}
            isPro={isPro}
            targetLanguageEnglishName={targetLanguageEnglishName}
            targetLanguageName={targetLanguageName}
            title="Discussion"
          />
          {isReportable && (
            <ReportErrorBtn
              className="btn btn-default report-error"
              collectionClozeSentence={collectionClozeSentence}
              disabled={updating}
              title="Report error"
              url={collectionClozeSentence.collectionClozeSentencesErrorUrl || reportErrorUrl}
            />
          )}
        </div>
      </div>
    );
  }

  renderSentenceEditorModal() {
    if(!this.state.sentenceEditorModalVisible) {
      return null;
    }

    const {
      collectionClozeSentence,
      collectionClozeSentencesUpsertUrl,
      isPro,
      isTextEditable,
      nextReviewByLevel,
      onUpdate,
    } = this.props;

    return (
      <CollectionClozeSentenceEditorModal
        isPro={isPro}
        isTextEditable={isTextEditable}
        nextReviewByLevel={nextReviewByLevel}
        onHidden={() => this.setState({ sentenceEditorModalVisible: false })}
        onUpdate={(sentence) => onUpdate(sentence)}
        ref={(el) => this.sentenceEditorModal = el}
        sentence={collectionClozeSentence}
        upsertUrl={collectionClozeSentence.collectionClozeSentencesUpsertUrl || collectionClozeSentencesUpsertUrl}
      />
    );
  }

  renderTokensBtn() {
    const { collectionClozeSentence } = this.props;

    if(!collectionClozeSentence.tokensCount) { 
      return null;
    }

    const { tokensVisible } = this.state;
    const { updateHasShownTokens } = this.props;

    return (
      <button className="btn btn-default toggle-tokens" onClick={() => this.setState({ tokensVisible: !tokensVisible }, () => { this.hasShownTokens && updateHasShownTokens(); this.hasShownTokens = true })} title="Toggle grammar">
        <Icon name={`chevron-${tokensVisible ? "up" : "down"}`} />
      </button>
    );
  }

  renderTokens() {
    if(!this.state.tokensVisible) {
      return null;
    }

    const {
      collectionClozeSentence,
      hasShownTokens,
      isPro,
      targetLanguageCode,
      targetLanguageIso,
      tokensUrl
    } = this.props;

    if(!isPro && hasShownTokens) {
      return (
        <div className="alert alert-success text-center" style={{ clear: "both", margin: "10px 0 5px" }}>
          <p style={{ fontWeight: "bold", marginBottom: 10 }}>Get full access to sentence grammar with Clozemaster Pro - subscribe today and get fluent faster!</p>
          <p><a className="btn btn-success joystix" href="/pro">Get Pro!</a></p>
        </div>
      );
    }

    return (
      <div className="scrollbar-always-visible" style={{ overflowY: "scroll", textAlign: "center", whiteSpace: "nowrap", width: "100%" }}>
        <Tokens
          targetLanguageCode={targetLanguageCode}
          targetLanguageIso={targetLanguageIso}
          tokenizeableId={collectionClozeSentence.id}
          tokenizeableType={"CollectionClozeSentence"}
          url={tokensUrl || collectionClozeSentence.tokensUrl}
        />
      </div>
    );
  }

  render() {
    const { collectionClozeSentence } = this.props;

    return (
      <tr className="round-result-sentence">
        <td>
          <div className="pull-right" style={{ marginLeft: 10 }}>
            {this.renderControls()}
          </div>
          <div style={{ fontSize: '2rem' }}>
            <ClozeSentence text={collectionClozeSentence.text} />
          </div>
          {this.renderTokens()}
          <div><small>{collectionClozeSentence.translation}</small></div>
          {this.renderSentenceEditorModal()}
        </td>
      </tr>
    );
  }
}

export default class RoundResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionClozeSentences: props.collectionClozeSentences,
      loadingLanguagePairingStats: true
    };
  }

  componentDidMount() {
    this.props.playSoundEffect();

    if(this.props.isSignedIn) {
      this.loadLanguagePairingStats();
    }
  }

  loadLanguagePairingStats() {
    this.setState({ loadingLanguagePairingStats: true });

    const data = this.isReviewScope() ? {} : { collection_id: this.props.collectionId };

    $.ajax({
      data,
      url: this.props.languagePairingUrl
    })
      .done((data) => {
        console.log('loadLanguagePairingStats', data);

        const {
          baseLanguageFlagIso,
          currentLevelPoints,
          currentWeekLeaderboardRank,
          dailyGoalHoursLeftToday,
          dailyGoalPointsPerDay,
          dailyGoalStreak,
          level,
          nextLevelPoints,
          numPointsToday,
          pointsToNextCurrentWeekLeaderboardRank,
          prevWeekLeaderboardRank,
          progressPerChunk,
          score,
          targetLanguageFlagIso,
          useProgressChunks
        } = data.languagePairing;

        const collectionsReadyForReview = data.collections.filter((c) => c.numReadyForReview > 0);

        let collectionState = {};
        if(this.props.collectionId) {
          const collection = data.collections.find((c) => c.id === this.props.collectionId);
          collectionState = {
            collectionName: collection.name,
            collectionNumMastered: collection.numMastered,
            collectionNumPlaying: collection.numPlaying,
            collectionNumReadyForReview: collection.numReadyForReview,
            collectionNumSentences: collection.numSentences,
            collectionSlug: collection.slug,
            nextCollectionToReview: collectionsReadyForReview[0] && collectionsReadyForReview[0].slug,
          };
        }

        this.setState(Object.assign(collectionState, {
          baseLanguageFlagIso,
          collectionsReadyForReview,
          currentLevelPoints,
          currentWeekLeaderboardRank,
          dailyGoalHoursLeftToday,
          dailyGoalPointsPerDay,
          dailyGoalStreak,
          level,
          loadingLanguagePairingStats: false,
          nextLevelPoints,
          numPointsToday,
          pointsToNextCurrentWeekLeaderboardRank,
          prevWeekLeaderboardRank,
          progressPerChunk,
          score,
          targetLanguageFlagIso,
          useProgressChunks
        }));
      })
      .fail(() => {
        alert('Oh no! There was an error loading the latest stats. Sorry about that. Please let us know if you see this message again.');
      });
  }

  renderStats() {
    const { elapsedTime, isTeachingCollection, numCorrect, numIncorrect, numNew, numReview, score } = this.props;
    return (
      <div>
        <h2 className="joystix text-center">Stats:</h2>
        <table className="table table-striped text-center stats" style={{ marginBottom: 0 }}>
          <tbody><tr>
              <td className="score">
                <span className="joystix">Score:</span> <span className="value">+{score}</span>
              </td>
              <td className="time">
                <span className="joystix">Time:</span> <span className="value">{Math.floor(elapsedTime / 60)}:{elapsedTime % 60 < 10 ? '0' : ''}{elapsedTime % 60}</span>
              </td>
            </tr>
            {!isTeachingCollection && (
              <tr>
                <td className="new">
                  <span className="joystix">New:</span> <span className="value">{numNew}</span>
                </td>
                <td className="review">
                  <span className="joystix">Review:</span> <span className="value">{numReview}</span>
                </td>
              </tr>
            )}
            <tr>
              <td className="correct">
                <span className="joystix">Correct:</span> <span className="value">{numCorrect}</span>
              </td>
              <td className="incorrect">
                <span className="joystix">Incorrect:</span> <span className="value">{numIncorrect}</span>
              </td>
            </tr>
        </tbody></table>
      </div>
    );
  }

  goToNextCollectionToReview() {
    window.location = window.location.toString().replace('collections/' + this.state.collectionSlug, 'collections/' + this.state.nextCollectionToReview);
  }

  renderNextRoundBtn() {
    if(this.props.isTeachingCollection) {
      return (
        <a className="btn btn-success joystix btn-block btn-lg" href="">
          Play Again <Icon name="repeat" />
        </a>
      );
    }

    if(!this.isReviewScope() || this.state.loadingLanguagePairingStats) {
      return (
        <button className="btn btn-success joystix btn-block btn-lg" disabled={this.props.isSignedIn && this.state.loadingLanguagePairingStats} onClick={this.props.onNextRound}>
          Continue <Icon name="chevron-right" />
        </button>
      );
    }

    const { collectionNumReadyForReview, collectionsReadyForReview, nextCollectionToReview } = this.state;

    if(collectionNumReadyForReview) {
      return (
        <button className="btn btn-primary joystix btn-block btn-lg" onClick={this.props.onNextRound}>
          Continue ({collectionNumReadyForReview}) <Icon name="chevron-right" />
        </button>
      );
    }

    if(!this.props.collectionId && collectionsReadyForReview.length) {
      return (
        <button className="btn btn-primary joystix btn-block btn-lg" onClick={this.props.onNextRound}>
          Continue ({collectionsReadyForReview.reduce((total, c) => total + c.numReadyForReview, 0)}) <Icon name="chevron-right" />
        </button>
      );
    }

    if(collectionsReadyForReview.length) {
      return (
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <select name="next_collection_to_review" className="form-control input-lg" value={nextCollectionToReview} onChange={(e) => this.setState({ nextCollectionToReview: e.target.value })}>
              {collectionsReadyForReview.map((c) => (
                <option key={c.id} value={c.slug}>{c.name} ({c.numReadyForReview})</option>
              ))}
            </select>
          </div>
          <div className="col-xs-12 col-sm-6">
            <button className="btn btn-primary joystix btn-block btn-lg" onClick={() => this.goToNextCollectionToReview()}>
              Review <Icon name="chevron-right" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="text-center joystix">No more sentences ready for review! &#x1f64c;</div>
    );
  }

  renderControls() {
    const { isPro, languagePairingWebUrl, onboarding } = this.props;
    const dashboardPath = onboarding && !isPro ? "/welcome-pro" : (languagePairingWebUrl || "/dashboard");
    return (
      <div className="row" style={{ marginBottom: 10 }}>
        <div className="col-xs-6">
          <a href={dashboardPath} className="btn btn-default joystix btn-block btn-lg">
            <Icon name="chevron-left" /> Dashboard
          </a>
        </div>
        <div className="col-xs-6">
          {this.renderNextRoundBtn()}
        </div>
      </div>
    );
  }

  renderDailyGoal() {
    const { dailyGoalHoursLeftToday, numPointsToday, dailyGoalPointsPerDay, dailyGoalStreak, loadingLanguagePairingStats } = this.state;

    if(loadingLanguagePairingStats) {
      return null;
    }

    if(!dailyGoalPointsPerDay) {
      return null;
    }

    return (
      <div className="text-center">
        <h2 className="joystix">Daily Goal:</h2>
        <DailyGoalStats
          hoursLeftToday={dailyGoalHoursLeftToday}
          numPointsToday={numPointsToday}
          pointsPerDay={dailyGoalPointsPerDay}
          streak={dailyGoalStreak}
          style={{ display: 'inline-block' }}
        />
      </div>
    );
  }

  renderCollectionProgress() {
    if(!this.props.collectionId) {
      return null;
    }

    const {
      startingNumMastered,
      startingNumPlaying,
      startingNumReadyForReview
    } = this.props;

    const {
      collectionName,
      collectionNumMastered,
      collectionNumPlaying,
      collectionNumReadyForReview,
      collectionNumSentences,
      progressPerChunk,
      useProgressChunks
    } = this.state;

    const numMasteredDiff = collectionNumMastered - startingNumMastered;
    const numPlayingDiff = collectionNumPlaying - startingNumPlaying;
    const numReadyForReviewDiff = collectionNumReadyForReview - startingNumReadyForReview;
    const pctMastered = pctStr(collectionNumMastered, collectionNumSentences);
    const pctPlaying = pctStr(collectionNumPlaying, collectionNumSentences);

    return (
      <div className="panel panel-default collection-progress">
        <div className="panel-heading joystix">{collectionName}</div>
        <ul className="list-group">
          <li className="list-group-item">
            <span className="joystix">
              Playing: {collectionNumPlaying.toLocaleString()} / {collectionNumSentences.toLocaleString()}
              {numPlayingDiff > 0 ? <span className="stat-change">+{numPlayingDiff}</span> : null}
            </span>
            <br />
            {numPlayingDiff > 0 ? <>{pctStr(startingNumPlaying, collectionNumSentences)}<span style={{ marginLeft: 4, marginRight: 4 }}><Icon name="arrow-right" /></span></> : null}
            {pctPlaying}
            {!!useProgressChunks && (
              <ChunkProgressBar
                includePopoverHelperBtn={true}
                num={collectionNumPlaying}
                pct={pctPlaying}
                perChunk={progressPerChunk}
                style={{ marginTop: 2 }}
                total={collectionNumSentences}
                type="playing"
              />
            )}
          </li>
          <li className="list-group-item">
            <span className="joystix">
              Mastered: {collectionNumMastered.toLocaleString()} / {collectionNumSentences.toLocaleString()}
              {numMasteredDiff > 0 ? <span className="stat-change">+{numMasteredDiff}</span> : null}
            </span> 
            <br />
            {numMasteredDiff > 0 ? <>{pctStr(startingNumMastered, collectionNumSentences)}<span style={{ marginLeft: 4, marginRight: 4 }}><Icon name="arrow-right" /></span></> : null}
            {pctMastered}
            {!!useProgressChunks && (
              <ChunkProgressBar
                num={collectionNumMastered}
                pct={pctMastered}
                perChunk={progressPerChunk}
                style={{ marginTop: 2 }}
                total={collectionNumSentences}
                type="mastered"
              />
            )}
          </li>
          <li className="list-group-item">
            <span className="joystix">
              Ready for Review: {collectionNumReadyForReview}
              {numReadyForReviewDiff > 0 ? <span className="stat-change">-{numReadyForReviewDiff}</span> : null}
            </span>
          </li>
        </ul>
      </div>
    );
  }

  renderLeveledUpIndicator() {
    if(this.props.leveledUp) {
      // up arrow, tada, hands raised
      return (
        <span className="leveled-up-indicator" style={{ color: "#5cb85c", paddingLeft: 10 }}>
          &#x2b06;&#x1f389;&#x1f64c;
        </span>
      );
    }
  }

  renderProgress() {
    const {
      baseLanguageFlagIso,
      currentLevelPoints,
      currentWeekLeaderboardRank,
      level,
      loadingLanguagePairingStats,
      nextLevelPoints,
      pointsToNextCurrentWeekLeaderboardRank,
      prevWeekLeaderboardRank,
      score,
      targetLanguageFlagIso
    } = this.state;

    if(loadingLanguagePairingStats) {
      return null;
    }

    let rankExtra = null;
    if(currentWeekLeaderboardRank === 1) {
      rankExtra = <span style={{ marginLeft: 6 }}>&#x1F3C6;</span>;
    }
    else if(currentWeekLeaderboardRank && currentWeekLeaderboardRank > 1 && pointsToNextCurrentWeekLeaderboardRank) {
      const nextRank = currentWeekLeaderboardRank - 1;
      rankExtra = <small><br />({pointsToNextCurrentWeekLeaderboardRank.toLocaleString()} points to {nextRank}<sup>{ordinalSuffix(nextRank)}</sup>)</small>;
    }

    let prevWeekRank = null;
    if(prevWeekLeaderboardRank) {
      const up = currentWeekLeaderboardRank > prevWeekLeaderboardRank;
      // <LeaderboardRankTrend />
      prevWeekRank = (
        <span data-toggle="tooltip" title={'Ranked ' + prevWeekLeaderboardRank + ' last week'}>
          <Icon name={'arrow-' + (up ? 'up' : 'down')} />
        </span>
      );
    }

    return (
      <div className="text-center">
        <h2 className="joystix">Progress:</h2>
        {this.renderCollectionProgress()}
        <div className="panel panel-default">
          <div className="panel-heading joystix">
            <FlagSprite flagIso={targetLanguageFlagIso} size={24} /> / <FlagSprite flagIso={baseLanguageFlagIso} size={24} />
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              <span className="joystix">Level: {level}{this.renderLeveledUpIndicator()}</span>
              <br />
              <div className="progress" style={{ height: 10, marginBottom: 4 }}>
                <div aria-valuemax="100" aria-valuemin="0" className="progress-bar progress-bar-success" role="progressbar" style={{ width: ((score - currentLevelPoints) / (nextLevelPoints - currentLevelPoints) * 100) + '%' }}></div>
              </div>
              {(nextLevelPoints - score).toLocaleString()} points to level {level + 1} - keep going!
            </li>
            {!!currentWeekLeaderboardRank && (
              <li className="list-group-item">
                <span className="joystix">Ranked: {currentWeekLeaderboardRank}<sup>{ordinalSuffix(currentWeekLeaderboardRank)}</sup>{prevWeekRank} this week</span>
                {rankExtra}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }

  renderProPromo() {
    if(!this.props.isSignedIn || this.props.isPro) {
      return null;
    }

    return (
      <div className="panel panel-default" style={{ marginTop: 20 }}>
        <div className="panel-body">
          <p className="text-center" style={{ fontSize: '1.5em' }}>Get fluent faster.</p>
          <a href="/pro" className="btn btn-block btn-lg pro-promo joystix">
            Get <span className="hidden-xs">Clozemaster Pro</span> <span className="glyphicon glyphicon-chevron-right"></span>
          </a>
        </div>
      </div>
    );
  }

  renderSignUpPromo() {
    if(this.props.isSignedIn) {
      return null;
    }

    let message = "Save your progress!";

    if(this.props.isTeachingCollection) {
      message = "Get fluent faster with Clozemaster!";
    }

    return (
      <div className="panel panel-default" style={{ marginTop: 20 }}>
        <div className="panel-body">
          <p className="text-center" style={{ fontSize: '1.5em' }}>{message}</p>
          <a href="/pro" className="btn btn-block btn-lg btn-success joystix">
            Sign Up <span className="glyphicon glyphicon-chevron-right"></span>
          </a>
        </div>
      </div>
    );
  }

  renderTeachingCollectionResultsForm() {
    if(!this.props.isTeachingCollection) {
      return null;
    }

    const params = new URLSearchParams(window.location.search);
    if(params.get("results") === "false") {
      return null;
    }

    const { answerTape, elapsedTime, numCorrect, numIncorrect, score } = this.props;
    const stats = { answerTape, elapsedTime, numCorrect, numIncorrect, score };

    return (
      <Panel>
        <TeachingCollectionResultsForm
          stats={stats}
          teachingCollectionResultsUrl={this.props.teachingCollectionResultsUrl}
        />
      </Panel>
    );
  }

  mergeUpdatedCollectionClozeSentence(collectionClozeSentence) {
    const collectionClozeSentences = JSON.parse(JSON.stringify(this.state.collectionClozeSentences));
    for(let i = 0, n = collectionClozeSentences.length; i < n; i++) {
      if(collectionClozeSentences[i].id === collectionClozeSentence.id) {
        collectionClozeSentences[i] = collectionClozeSentence;
        break;
      }
    }
    this.setState({ collectionClozeSentences });
  }

  renderSentences() {
    const { collectionClozeSentences } = this.state;

    const sentences = collectionClozeSentences.map((ccs) => (
      <RoundResultsCollectionClozeSentenceTableRow
        baseLanguageEnglishName={this.props.baseLanguageEnglishName}
        collectionClozeSentence={ccs}
        collectionClozeSentencesUpsertUrl={this.props.collectionClozeSentencesUpsertUrl}
        collectionsUrl={this.props.collectionsUrl}
        hasShownTokens={this.props.hasShownTokens}
        isPro={this.props.isPro}
        isReportable={!this.props.isCollectionEditable}
        isSignedIn={this.props.isSignedIn}
        isTextEditable={this.props.isCollectionEditable}
        isTtsAvailable={this.props.isTtsAvailable}
        key={ccs.id}
        nextReviewByLevel={this.props.nextReviewByLevel}
        updateHasShownTokens={this.props.updateHasShownTokens}
        onUpdate={(collectionClozeSentence) => this.mergeUpdatedCollectionClozeSentence(collectionClozeSentence)}
        playTts={this.props.playTts}
        reportErrorUrl={this.props.reportErrorUrl}
        targetLanguageCode={this.props.targetLanguageCode}
        targetLanguageEnglishName={this.props.targetLanguageEnglishName}
        targetLanguageIso={this.props.targetLanguageIso}
        targetLanguageName={this.props.targetLanguageName}
        tokensUrl={this.props.tokensUrl}
        updateSentence={(attr, value) => {
          this.props.updateSentence({
            sentence: ccs,
            attr,
            value,
            callback: () => {
              ccs[attr] = value;
              this.mergeUpdatedCollectionClozeSentence(ccs);
            }
          });
        }}
        update={this.props.updating}
      />
    ));

    return (
      <div>
        <h2 className="joystix text-center">Sentences:</h2>
        <table className="table table-striped" style={{ marginBottom: 0, tableLayout: "fixed" }}>
          <tbody>
            {sentences}
          </tbody>
        </table>
      </div>
    );
  }

  isReviewScope() {
    return this.props.scope === 'ready_for_review';
  }

  render() {
    const { numCorrect, numIncorrect, score } = this.props;
    /*
      <div>
        <div>Round results</div>
        <div>Score: {score}</div>
        <div>Correct: {numCorrect}</div>
        <div>Incorrect: {numIncorrect}</div>
        {uniqueSentences.map((ccs) => <div>{ccs.text}</div>)}
        <button onClick={() => this.nextRound()}>Continue</button>
      </div>
      */
    return (
      <div className="done round-complete" style={{ display: 'block' }}>
        {this.renderControls()}
        <div className="container-fluid">
          <div className={'panel panel-' + (this.isReviewScope() ? 'primary' : 'success') + ' results'} style={{ display: 'block' }}>
            <div className="panel-heading joystix text-center">Round Results</div>
            <div className="panel-body container-fluid">
              {this.renderStats()}
              {this.renderProPromo()}
              {this.renderSignUpPromo()}
              {this.renderTeachingCollectionResultsForm()}
              {this.renderDailyGoal()}
              {this.renderProgress()}
              {this.renderSentences()}
            </div>
          </div>
        </div>
        {this.renderControls()}
      </div>
    );
                  /*
                  <h2 class="joystix text-center">Daily Goal:</h2>
                  <div class="daily-goal text-center"><div class="points joystix">
                      Points:
                      <span class="value ">28</span> / 1000
                    </div>
                    <div class="streak joystix">
                      Streak:
                      <span class="value zero">0</span>
                      <small class="days zero">Days</small>
                      <span class="glyphicon glyphicon-fire includes-today" data-toggle="tooltip" title="" data-original-title="972 points to go for today!"></span>
                    </div>
                    <div class="hours-left joystix">
                      <strong class="stat">Time remaining:</strong>
                      <span class="value joystix">16</span>
                      <small class="joystix">Hours</small>
                    </div>
                  </div>
                  <h2 class="joystix text-center">Progress:</h2>
                  <div class="progress-entries">
                    <div class="progress-entry played">
                      <div class="subheader">
                        <span class="joystix">Played</span><span class="plus" style="display: inline;"><span class="joystix">:</span> <span class="count">+2</span></span>
                      </div>
                      <div class="row">
                        <div class="col-xs-12 col-sm-6 affected-count" data-current="2355" data-total="24139">
                          <span class="joystix">Fast Track:</span>
                          <span class="value increased">2,357</span>
                          <div class="percents" style="display: block;">
                            <span class="from">9.756%</span> <span class="glyphicon glyphicon-arrow-right"></span> <span class="to">9.764%</span>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 affected-count" data-current="2517" data-total="152237">
                          <span class="joystix">Total:</span>
                          <span class="value increased">2,519</span>
                          <div class="percents" style="display: block;">
                            <span class="from">1.653%</span> <span class="glyphicon glyphicon-arrow-right"></span> <span class="to">1.655%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="progress-entry mastered">
                      <div class="subheader">
                        <span class="joystix">Mastered</span><span class="plus"><span class="joystix">:</span> <span class="count"></span></span>
                      </div>
                      <div class="row">
                        <div class="col-xs-12 col-sm-6 affected-count" data-current="117" data-total="24139">
                          <span class="joystix">Fast Track:</span>
                          <span class="value">117</span>
                          <div class="percents">
                            <span class="from"></span> <span class="glyphicon glyphicon-arrow-right"></span> <span class="to"></span>
                          </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 affected-count" data-current="117" data-total="152237">
                          <span class="joystix">Total:</span>
                          <span class="value">117</span>
                          <div class="percents">
                            <span class="from"></span> <span class="glyphicon glyphicon-arrow-right"></span> <span class="to"></span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="progress-entry challenge-level" data-level="49" data-next-level-points="1182" data-next-next-level-points="1223" data-points-til-next-level="414">
                      <div class="level">
                        <span class="sprite flag_24_ES"></span>
                        /
                        <span class="sprite flag_24_US"></span>

                        <span class="joystix">Level:</span>
                        <span class="value joystix">49</span>
                        <span class="levelup joystix">
                          +<span class="levels">1</span>
                          <span class="glyphicon glyphicon-arrow-up"></span>
                        </span>
                      </div>
                      <div class="progress">
                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="0" class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" style="width: 67.3435%;">
                          <small class="joystix">
                            <span class="more-points">386</span> <span class="hidden-xs">more</span> points to level <span class="next-level">50</span>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="progress-entry overall-level" data-level="74" data-next-level-points="2679" data-next-next-level-points="2765" data-points-til-next-level="1535">
                      <div class="level">
                        <span class="joystix">Overall Level:</span>
                        <span class="value joystix">74</span>
                        <span class="levelup joystix">
                          +<span class="levels">1</span>
                          <span class="glyphicon glyphicon-arrow-up"></span>
                        </span>
                      </div>
                      <div class="progress">
                        <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="0" class="progress-bar progress-bar-success progress-bar-striped" role="progressbar" style="width: 43.7477%;">
                          <small class="joystix">
                            <span class="more-points">1,507</span> <span class="hidden-xs">more</span> points to level <span class="next-level">75</span>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="rankings" data-challenge-alltime="1416" data-challenge-weekly="7803" data-user-alltime="4175" data-user-weekly="41687"><div class="progress-entry rankings">
                        <div class="subheader">
                          <span class="sprite flag_24_ES"></span>
                          /
                          <span class="sprite flag_24_US"></span>
                          <span class="joystix">Ranking</span>
                        </div>
                        <div class="row">
                          <div class="col-xs-12 col-sm-6">
                            <span class="joystix">Weekly:</span>
                            <div class="ranked">
                              <span>
                                <span class="value">1,140<sup class="ord">th</sup></span><span class="glyphicon glyphicon-arrow-up uptrend" data-toggle="tooltip" title="" data-original-title="Ranked 7,772nd yesterday UTC"></span></span>
                            </div>

                            <div>
                              <small>4 points to <span class="value">1,139<sup class="ord">th</sup></span></small>
                            </div>
                          </div>
                          <div class="col-xs-12 col-sm-6">
                            <span class="joystix">All-Time:</span>
                            <div class="ranked">
                              <span>
                                <span class="value">1,416<sup class="ord">th</sup></span><span class="glyphicon glyphicon-arrow-up uptrend" data-toggle="tooltip" title="" data-original-title="Ranked 1,417th yesterday UTC"></span></span>
                            </div>

                            <div>
                              <small>16 points to <span class="value">1,415<sup class="ord">th</sup></span></small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="progress-entry rankings">
                        <div class="subheader joystix">Overall Ranking</div>
                        <div class="row">
                          <div class="col-xs-12 col-sm-6">
                            <span class="joystix">Weekly:</span>
                            <div class="ranked">
                              <span>
                                <span class="value">5,345<sup class="ord">th</sup></span><span class="glyphicon glyphicon-arrow-up uptrend" data-toggle="tooltip" title="" data-original-title="Ranked 41,559th yesterday UTC"></span></span>
                            </div>

                            <div>
                              <small>2 points to <span class="value">5,344<sup class="ord">th</sup></span></small>
                            </div>
                          </div>
                          <div class="col-xs-12-col-sm-6">
                            <span class="joystix">All-Time:</span>
                            <div class="ranked">
                              <span>
                                <span class="value">4,174<sup class="ord">th</sup></span><span class="glyphicon glyphicon-arrow-down downtrend" data-toggle="tooltip" title="" data-original-title="Ranked 4,172nd yesterday UTC"></span></span>
                            </div>

                            <div>
                              <small>57 points to <span class="value">4,173<sup class="ord">rd</sup></span></small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  */
  }
}

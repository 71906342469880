import React from "react";

import ShortcutsList from "./ShortcutsList";
import TextToSpeechSettings from "./TextToSpeechSettings";

const FontSizer = function({ label, onChange, value }) {
  const floatValue = parseFloat(value.replace(/-/, "."));
  return (
    <div className={`${label.toLowerCase().replace(/\W/, "-")}-font-sizer`} style={{ display: "flex", flexDirection: "row", marginTop: 10, textAlign: "left" }}>
      <div style={{ flex: 2 }}>
        {label}
      </div>
      <div style={{ flex: 1, textAlign: "center" }}>{floatValue}x</div>
      <div style={{ flex: 3, marginLeft: 10 }}>
        <input
          min="1"
          max="4"
          onChange={(e) => onChange(e.target.value.toString().replace(/\./, "-") + "x")}
          step="0.5"
          type="range"
          value={floatValue}
        />
        <div className={`font-size-${value}`}>Sample text.</div>
      </div>
    </div>
  );
};

export default class GameSettings extends React.Component {
  constructor(props) {
    super(props);

    // NOTE! be sure to update UserLanguagePairing whitelist when adding settings
    this.state = Object.assign({}, {
      accentShortcuts: 'on',
      audioRecordings: 'on',
      clozemasterTextToSpeech: 'on',
      enterSubmitsEmpty: 'on',
      fitTextInputWidth: 'on',
      hints: 'on',
      hintFontSize: '1x',
      hotkeys: 'on',
      imageBackground: 'off',
      imageToggle: 'after',
      keyDownClearsTextInputAfterAnswering: 'on',
      leveledUpNotifications: 'on',
      listeningTextToSpeechVoice: 'random',
      listeningSkillAutoShowSentence: 'off',
      manualMasterResetConfirm: 'on',
      notesFontSize: '1x',
      pronunciation: 'on',
      pronunciationFontSize: '1x',
      soundEffects: 'on',
      spellingHints: 'on',
      textToSpeech: 'on',
      textToSpeechSpeed: 1,
      textToSpeechVoice: 'random',
      textToSpeechLooping: 'off',
      theme: 'light',
      translationFontSize: '1x',
      translations: 'visible',
      transliteration: 'on',
      transliterationFontSize: '1x',
      typingColorHint: 'on',
    }, props.initialSettings);
  }

  componentDidMount() {
    // GameSettings component sets default settings
    this.props.onUpdate && this.props.onUpdate(this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.theme !== this.state.theme) {
      $('body').toggleClass('dark-mode', this.state.theme === 'dark');
    }
  }

  updateSetting(attr, value) {
    const { onUpdate, url } = this.props;

    const update = {};
    update[attr] = value;

    const newSettings = Object.assign({}, this.state, update);

    this.setState(newSettings, () => onUpdate && onUpdate(newSettings));

    $.ajax({
      url: url,
      data: {
        settings: newSettings
      },
      method: 'post'
    })
      .fail(() => {
        // TODO!
      });
  }

  renderBtnGroupBtn(attr, value, currentValue, content) {
    return (
      <button
        className={'btn ' + (currentValue === value ? 'btn-success active' : 'btn-default')}
        key={attr + '-' + value}
        onClick={() => this.updateSetting(attr, value)}
        type="button"
        value={value}
      >
        {content}
      </button>
    );
  }

  renderHints() {
    const hints = this.state.hints;
    return (
      <div className="group hints">
        <div className="header">
          <div className="joystix">
            Hints:
          </div>
          <small>(appear above the missing word when available)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('hints', 'on', hints, 'On')}
          {this.renderBtnGroupBtn('hints', 'off', hints, 'Off')}
        </div>
      </div>
    );
  }

  renderSpellingHints() {
    if(this.props.isPlayingMultipleChoice) {
      return null;
    }

    const spellingHints = this.state.spellingHints;
    return (
      <div className="group spelling-hints">
        <div className="header">
          <div className="joystix">
            Spelling Hints:
          </div>
          <small>(lets you know if your answer is incorrect by up to 2 letters)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('spellingHints', 'on', spellingHints, 'On')}
          {this.renderBtnGroupBtn('spellingHints', 'off', spellingHints, 'Off')}
        </div>
      </div>
    );
  }

  renderClearAfterAnswering() {
    if(this.props.isPlayingMultipleChoice) {
      return null;
    }

    const { keyDownClearsTextInputAfterAnswering } = this.state;

    return (
      <div className="group clear-after-answering">
        <div className="header">
          <div className="joystix">
            Clear After Answering:
          </div>
          <small>(keypress clears the text input after answering so you can practice re-typing the answer)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('keyDownClearsTextInputAfterAnswering', 'on', keyDownClearsTextInputAfterAnswering, 'On')}
          {this.renderBtnGroupBtn('keyDownClearsTextInputAfterAnswering', 'off', keyDownClearsTextInputAfterAnswering, 'Off')}
        </div>
      </div>
    );
  }


  renderColorHint() {
    if(this.props.isPlayingMultipleChoice) {
      return null;
    }

    const typingColorHint = this.state.typingColorHint;
    return (
      <div className="group color-hint">
        <div className="header">
          <div className="joystix">
            Typing Color Hint:
          </div>
          <small>(see if you're entering the correct answer as you type)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('typingColorHint', 'on', typingColorHint, 'On')}
          {this.renderBtnGroupBtn('typingColorHint', 'off', typingColorHint, 'Off')}
        </div>
      </div>
    );
  }

  renderEnterSubmitsEmpty() {
    if(this.props.isPlayingMultipleChoice) {
      return null;
    }

    const { enterSubmitsEmpty } = this.state;
    return (
      <div className="group enter-submit-empty">
        <div className="header">
          <div className="joystix">
            Enter Submits Empty:
          </div>
          <small>(pressing Enter when the input is empty will submit a blank answer)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('enterSubmitsEmpty', 'on', enterSubmitsEmpty, 'On')}
          {this.renderBtnGroupBtn('enterSubmitsEmpty', 'off', enterSubmitsEmpty, 'Off')}
        </div>
      </div>
    );
  }

  renderTextBoxSize() {
    if(this.props.isPlayingMultipleChoice) {
      return null;
    }

    const { fitTextInputWidth } = this.state;
    return (
      <div className="group">
        <div className="header">
          <div className="joystix">
            Text Box Size:
          </div>
          <small>(text box size can change to match the missing word)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('fitTextInputWidth', 'on', fitTextInputWidth, 'Changes')}
          {this.renderBtnGroupBtn('fitTextInputWidth', 'off', fitTextInputWidth, 'Always the Same')}
        </div>
      </div>
    );
  }

  renderAudioRecordings() {
    const { audioRecordingsAvailable } = this.props;
    const { audioRecordings } = this.state;

    if(!audioRecordingsAvailable) {
      return null;
    }

    return (
      <div className="group audio-recordings" style={{ display: "block" }}>
        <div className="header">
          <div className="joystix">Prefer Audio Recordings:</div>
          <small>(when available, otherwise will fallback to text-to-speech if available)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('audioRecordings', 'on', audioRecordings, 'On')}
          {this.renderBtnGroupBtn('audioRecordings', 'off', audioRecordings, 'Off')}
        </div>
      </div>
    );
  }

  renderTextToSpeech() {
    const { clozemasterTtsAvailable, systemTtsAvailable, systemTtsVoices } = this.props;
    const { clozemasterTextToSpeech, textToSpeech, textToSpeechSpeed, textToSpeechVoice, textToSpeechLooping } = this.state;
    return (
      <TextToSpeechSettings
        clozemasterTextToSpeech={clozemasterTextToSpeech}
        clozemasterTtsAvailable={clozemasterTtsAvailable}
        onSpeedChanged={(speed) => this.updateSetting('textToSpeechSpeed', speed)}
        onVoiceSelected={(voice) => this.updateSetting('textToSpeechVoice', voice)}
        renderBtnGroupBtn={(...args) => this.renderBtnGroupBtn(...args)}
        systemTtsAvailable={systemTtsAvailable}
        systemTtsVoices={systemTtsVoices}
        textToSpeech={textToSpeech}
        textToSpeechLooping={textToSpeechLooping}
        textToSpeechSpeed={textToSpeechSpeed}
        textToSpeechVoice={textToSpeechVoice}
      />
    );
  }

  renderAccentShortcutsToggle() {
    if(this.props.isPlayingMultipleChoice) {
      return null;
    }

    return (
      <div className="group">
        <div className="header joystix">
          Accent Shortcuts:
        </div>
        <div className="checkbox">
          <label>
            <input type="checkbox" name="game_settings_accent_shortcuts" id="game_settings_accent_shortcuts" checked={this.state.accentShortcuts === 'on'} onChange={(e) => this.updateSetting('accentShortcuts', e.target.checked ? 'on' : 'off')} />
            Enabled
          </label>
        </div>
        <small>alt+letter to insert accented version of letter pressed. Press letter multiple times while holding alt key for different available accents. May conflict with system shortcuts.</small>
      </div>
    );
  }

  renderShortcutHotkeysList() {
    return (
      <div className="group">
        <div className="header joystix">
          Shortcut Hotkeys:
        </div>
        <div className="checkbox">
          <label>
            <input type="checkbox" name="game_settings_hotkeys" id="game_settings_hotkeys" value="1" checked={this.state.hotkeys === 'on'} onChange={(e) => this.updateSetting('hotkeys', e.target.checked ? 'on' : 'off')} /> Enabled
          </label>
        </div>
        <ShortcutsList />
      </div>
    );
  }

  renderDarkMode() {
    const { theme } = this.state;

    return (
      <div className="group dark-mode">
        <div className="header joystix">
          Dark Mode:
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('theme', 'dark', theme, 'On')}
          {this.renderBtnGroupBtn('theme', 'light', theme, 'Off')}
        </div>
      </div>
    );
  }

  renderLeveledUpNotificationsToggle() {
    if(!this.props.isSignedIn) {
      return null;
    }

    const { leveledUpNotifications } = this.state;

    return (
      <div className="group leveled-up-notifications">
        <div className="header joystix">
          Leveled Up Notifications:
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('leveledUpNotifications', 'on', leveledUpNotifications, 'On')}
          {this.renderBtnGroupBtn('leveledUpNotifications', 'off', leveledUpNotifications, 'Off')}
        </div>
      </div>
    );
  }

  renderFontSizes() {
    const {
      hintFontSize,
      notesFontSize,
      pronunciationFontSize,
      transliterationFontSize,
      translationFontSize,
    } = this.state;

    return (
      <div className="group font-sizes">
        <div className="header joystix">
          Font Sizes:
        </div>
        <FontSizer
          label="Hint"
          onChange={(value) => this.updateSetting("hintFontSize", value)}
          value={hintFontSize}
        />
        <FontSizer
          label="Notes"
          onChange={(value) => this.updateSetting("notesFontSize", value)}
          value={notesFontSize}
        />
        <FontSizer
          label="Pronunciation"
          onChange={(value) => this.updateSetting("pronunciationFontSize", value)}
          value={pronunciationFontSize}
        />
        <FontSizer
          label="Translation"
          onChange={(value) => this.updateSetting("translationFontSize", value)}
          value={translationFontSize}
        />
        <FontSizer
          label="Transliteration"
          onChange={(value) => this.updateSetting("transliterationFontSize", value)}
          value={transliterationFontSize}
        />
      </div>
    );

    // return (
    //   <>
    //     {this.renderTranslationFontSize()}
    //     {this.renderHintFontSize()}
    //     {this.renderNotesFontSize()}
    //   </>
    // );
  }

  renderTranslationFontSize() {
    const { translationFontSize } = this.state;

    return (
      <div className="group translation-font-size">
        <div className="header joystix">
          Translation Font Size:
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('translationFontSize', '1x', translationFontSize, '1x')}
          {this.renderBtnGroupBtn('translationFontSize', '1-5x', translationFontSize, '1.5x')}
          {this.renderBtnGroupBtn('translationFontSize', '2x', translationFontSize, '2x')}
          {this.renderBtnGroupBtn('translationFontSize', '3x', translationFontSize, '3x')}
        </div>
      </div>
    );

  }

  renderHintFontSize() {
    const { hintFontSize } = this.state;

    return (
      <div className="group hint-font-size">
        <div className="header joystix">
          Hint Font Size:
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('hintFontSize', '1x', hintFontSize, '1x')}
          {this.renderBtnGroupBtn('hintFontSize', '1-5x', hintFontSize, '1.5x')}
          {this.renderBtnGroupBtn('hintFontSize', '2x', hintFontSize, '2x')}
          {this.renderBtnGroupBtn('hintFontSize', '3x', hintFontSize, '3x')}
        </div>
      </div>
    );
  }

  renderNotesFontSize() {
    const { notesFontSize } = this.state;

    return (
      <div className="group notes-font-size">
        <div className="header joystix">
          Notes Font Size:
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('notesFontSize', '1x', notesFontSize, '1x')}
          {this.renderBtnGroupBtn('notesFontSize', '1-5x', notesFontSize, '1.5x')}
          {this.renderBtnGroupBtn('notesFontSize', '2x', notesFontSize, '2x')}
          {this.renderBtnGroupBtn('notesFontSize', '3x', notesFontSize, '3x')}
        </div>
      </div>
    );
  }

  renderManualMasterResetConfirmToggle() {
    if(!this.props.isSignedIn) {
      return null;
    }

    const { manualMasterResetConfirm } = this.state;


    return (
      <div className="group manual-master-reset-confirm">
        <div className="header joystix">
          Manual Master/Reset Confirmation:
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('manualMasterResetConfirm', 'on', manualMasterResetConfirm, 'On')}
          {this.renderBtnGroupBtn('manualMasterResetConfirm', 'off', manualMasterResetConfirm, 'Off')}
        </div>
      </div>
    );
  }

  renderListeningSkillAutoShowSentence() {
    const { listeningSkillAutoShowSentence } = this.state;
    const { isPlayingListening } = this.props;

    if(!isPlayingListening) {
      return null;
    }

    return (
      <div className="group listening-auto-show">
        <div className="header joystix">
          Automatically Show Sentence After First Listen:
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('listeningSkillAutoShowSentence', 'on', listeningSkillAutoShowSentence, 'On')}
          {this.renderBtnGroupBtn('listeningSkillAutoShowSentence', 'off', listeningSkillAutoShowSentence, 'Off')}
        </div>
        <div style={{ marginTop: 4 }}>(when playing listening skill)</div>
      </div>
    );
  }

  renderTransliterationToggle() {
    const { transliterationsAvailable } = this.props;
    const { transliteration } = this.state;

    if(!transliterationsAvailable) {
      return null;
    }

    return (
      <div className="group transliteration" style={{ display: 'block' }}>
        <div className="header">
          <div className="joystix">Transliteration:</div>
          <small>(shown after answering when available)</small>
        </div>
        <div className="btn-group" data-toggle="buttons">
          {this.renderBtnGroupBtn('transliteration', 'on', transliteration, 'On')}
          {this.renderBtnGroupBtn('transliteration', 'off', transliteration, 'Off')}
        </div>
      </div>
    );
  }

  render() {
    const {
      imageBackground,
      imageToggle,
      isPlayingMultipleChoice,
      pronunciation,
      soundEffects,
      translations,
      transliteration
    } = this.state;

    return (
      <div className="game-settings text-center">
        {this.renderListeningSkillAutoShowSentence()}
        {this.renderAudioRecordings()}
        <div className="group translations">
          <div className="header joystix">
            Translations:
          </div>
          <div className="btn-group" role="group" aria-label="translations">
            {this.renderBtnGroupBtn('translations', 'visible', translations, 'Visible')}
            {this.renderBtnGroupBtn('translations', 'show-after', translations, <span><span className="hidden-xs">Show </span>After Answering</span>)}
            {this.renderBtnGroupBtn('translations', 'hidden', translations, 'Hidden')}
          </div>
        </div>
        {!isPlayingMultipleChoice && this.renderHints()}
        {!isPlayingMultipleChoice && this.renderSpellingHints()}
        {!isPlayingMultipleChoice && this.renderColorHint()}
        {!isPlayingMultipleChoice && this.renderTextBoxSize()}
        {!isPlayingMultipleChoice && this.renderEnterSubmitsEmpty()}
        {!isPlayingMultipleChoice && this.renderClearAfterAnswering()}
        <div className="group image-toggle">
          <div className="header">
            <div className="joystix">Image Toggle:</div>
            <small>(toggle button, if sentence image available)</small>
          </div>
          <div className="btn-group" role="group" aria-label="translations">
            {this.renderBtnGroupBtn('imageToggle', 'after', imageToggle, 'After Answering')}
            {this.renderBtnGroupBtn('imageToggle', 'always', imageToggle, 'Before and After')}
            {this.renderBtnGroupBtn('imageToggle', 'off', imageToggle, 'Off')}
          </div>
        </div>
        <div className="group image-background">
          <div className="header">
            <div className="joystix">Image Background:</div>
            <small>(use sentence image as background, if available)</small>
          </div>
          <div className="btn-group" role="group" aria-label="translations">
            {this.renderBtnGroupBtn('imageBackground', 'on', imageBackground, 'On')}
            {this.renderBtnGroupBtn('imageBackground', 'off', imageBackground, 'Off')}
          </div>
        </div>
        <div className="group pronunciation" style={{ display: 'block' }}>
          <div className="header">
            <div className="joystix">Pronunciation:</div>
            <small>(shown after answering when available)</small>
          </div>
          <div className="btn-group" data-toggle="buttons">
            {this.renderBtnGroupBtn('pronunciation', 'on', pronunciation, 'On')}
            {this.renderBtnGroupBtn('pronunciation', 'off', pronunciation, 'Off')}
          </div>
        </div>
        {this.renderTransliterationToggle()}
        <div className="group">
          <div className="header joystix">
            Sound Effects:
          </div>
          <div className="btn-group" data-toggle="buttons">
            {this.renderBtnGroupBtn('soundEffects', 'on', soundEffects, 'On')}
            {this.renderBtnGroupBtn('soundEffects', 'off', soundEffects, 'Off')}
          </div>
        </div>
        {this.renderTextToSpeech()}
        {this.renderDarkMode()}
        {/*
        <div className="group">
        <div className="header">
        <a className="btn btn-success joystix btn-block" href="/languages/spa-eng/play/fast-track/multiple-choice">
        Switch to
        multiple choice
        <span className="glyphicon glyphicon-chevron-right"></span>
        </a>
        <small>
        Cloze mastery score multiplier is <span className="joystix">8</span> for text input vs. <span className="joystix">4</span> for multiple choice. Text input is more difficult than multiple choice, so you score more points!
        <strong>Switching to text input will also start a new round (your progress has been saved).</strong>
        </small>
        </div>
        </div>
        */}
        {this.renderLeveledUpNotificationsToggle()}
        {this.renderManualMasterResetConfirmToggle()}
        {this.renderFontSizes()}
        {this.renderAccentShortcutsToggle()}
        {this.renderShortcutHotkeysList()}
      </div>
    );
  }
}
